import React from "react";

const Container = (props) => {
	const { className, small, wide, ...otherProps } = props;

	return (
		<div className={className + ` flex flex-col w-full ${small ? "max-w-[32rem]" : wide ? "max-w-[56rem]" : "sm:w-[32rem] md:w-[44rem]"}`} {...otherProps} />
	);
};

export default Container;
