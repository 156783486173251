import { useState } from "react";

const useLocalStorage = (key, defaultValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === "object") {
			const value = window.localStorage.getItem(key);
			return value ? value : defaultValue;
		}

		return defaultValue;
	});

	const setValue = (value) => {
		if (typeof window === "object") {
			setStoredValue(value);

			if (value === null)
				window.localStorage.removeItem(key);
			else
				window.localStorage.setItem(key, value);
		}
	};

	return [storedValue, setValue];
};

export default useLocalStorage;
