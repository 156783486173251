import axios from "axios";
import useSWR from "swr";
import useLocalStorage from "./useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";

const fetcher = async url => {
	try {
		return (await axios.get(process.env.REACT_APP_BASE_API_URL + url, {
			headers: {
				authorization: "Bearer " + window.localStorage.getItem("token")
			}
		})).data;
	} catch (err) {
		const error = {};

		if (!err.response) {
			error.statusCode = 500;
			error.data = {
				code: err.code,
				displayMessage: "An unknown error occurred"
			};
		} else {
			error.statusCode = err.response.status;
			error.data = err.response.data;
		}

		throw error;
	}
};

const useEndpoint = (path, element) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [token, setToken] = useLocalStorage("token", null);
	const { data, error } = useSWR(path, fetcher, {
		revalidateOnFocus: false
	});

	if (error) {
		if ((error.statusCode === 401 || error.statusCode === 403) && error.data.code === "NOT_LOGGED_IN") {
			if (token !== null)
				setToken(null);

			if (pathname !== "/" && pathname !== "/login" && pathname !== "/signup")
				navigate("/");
		}
	}

	return {
		[element]: data ? data[element] : null,
		loading: !error && !data,
		error: error
	};
};

export default useEndpoint;
